<template>
    <div class="little-user">
    <b-row class="align-items-center">
        <b-col cols="auto">
            <LetterIcon :obj="user" :title="user.name" class="student-icon sm"/>
        </b-col>
        <b-col>
            <h5 class="mb-0">{{ user.name }}</h5>
        </b-col>
    </b-row>
    </div>
</template>

<script>
    import LetterIcon from '@/views/parts/general/LetterIcon'

    export default {
        components: {
            LetterIcon
        },
        data() {
            return {

            }
        },
        props: {
            user: {}
        },
        methods: {

        },
        watch: {

        },
        mounted() {

        },
        computed: {

        }

    }
</script>

<style scoped lang="scss">

</style>






