<template>
    <b-modal id="schedulesettingsmodal" size="md" class="schedulesettingsmodal" modal-class="modal-right-pane">
        <template v-slot:modal-header="{ close }">
            <h2 >{{ $t('settings') }}</h2>
            <a class="pointer position-relative modal-close" @click="close()">
                <CloseSvg/>
            </a>
        </template>
        <h3>{{ $t('show_schedule_from') }}</h3>
        <div class="select-wrapper form-group">
            <b-form-select class="form-control form-control-white"
                           v-model="form.schedule_from">
                <option v-for="(option, idx) in timedurations"
                        :key="'timedur-'+idx"
                        :value="$options.filters.timeFromSeconds(option)+':00'">
                    {{ option | timeFromSeconds }}
                </option>
                <option value="24:00:00">24:00</option>
            </b-form-select>
        </div>
        <h3>{{ $t('show_schedule_to') }}</h3>
        <div class="select-wrapper form-group">
            <b-form-select class="form-control form-control-white"
                           v-model="form.schedule_to">
                <option v-for="(option, idx) in timedurations"
                        :key="'timedur-'+idx"
                        :value="$options.filters.timeFromSeconds(option)+':00'">
                    {{ option | timeFromSeconds }}
                </option>
                <option value="24:00:00">24:00</option>
            </b-form-select>
        </div>
<!--        <SelectGroup class="default" v-model="form.schedule_from" :options="timedurations" title="title"/>-->

        <template v-slot:modal-footer>
            <b-button class="btn-themed btn-white" @click="save">{{ $t('save') }}</b-button>
        </template>
    </b-modal>
</template>

<script>
    import CloseSvg from '@/assets/svg/close.svg'
    import { UsersService } from '@/services/api.service'
    // import SelectGroup from "@/views/parts/general/form/SelectGroup"

    export default {
        components: {
            CloseSvg,
            // SelectGroup
        },
        data() {
            return {
                timedurations: this.getListNumbers(0, (24 * 60 * 60) - (60 * 60), 60 * 60),
                form: {
                    schedule_from: this.$store.state.user.schedule_from,
                    schedule_to: this.$store.state.user.schedule_to
                }
            }
        },
        props: {
        },
        methods: {
            save() {
                UsersService.updateScheduleSettings(this.form).then(res => {
                    let r = this.apiResponse(res)
                    if(r.success) {
                        this.$store.commit('saveUser', { user: r.data, vm: this } )
                        this.$bvModal.hide('schedulesettingsmodal')
                        this.$emit('refresh')
                    } else {
                        this.$error(r.data)
                    }
                })
            },
        },
        mounted() {

        },

    }
</script>

<style scoped lang="scss">

</style>






