<template>
  <section id="schedule">
    <h1 id="page-title">
      <b-row class="align-items-center">
        <b-col>{{ $t('schedule') }}</b-col>
        <b-col class="d-md-none d-block" cols="auto">
          <button class="btn-themed" @click="addClass">
            <PlusSvg class="svg-white svg-icon-sm"/>
          </button>
        </b-col>
      </b-row>
    </h1>
    <b-row class="row-wide" v-if="students_count > 0 || events.length">
      <b-col md="auto">
        <div v-if="[1,3].includes($store.state.user.primary_role_id)">
          <button class="btn-themed w-100 d-md-block d-none mb-24" @click="addClass">{{ $t('add_class') }}</button>
          <div class="month-calendar-wrapper d-md-block d-none">
            <full-calendar id="month-calendar"
                           ref="mcalendar"
                           :events="[]"
                           @day-click="dayClick"
                           :config="monthconfig">
            </full-calendar>
            <div class="search-wrap">
              <StudentSearch :dropdown="true"
                             :onlyactive="true"
                             :showgroups="true"
                             @selected="studentsSelected"
                             :passedstudents="students"
                             :passedgroups="groups"/>
            </div>
          </div>
        </div>
        <div v-if="[4,5].includes($store.state.user.primary_role_id)" class="left-sideblock">
          <div class="search-input-wrapper mb-24">
            <SearchSvg class="position-absolute search-icon"/>
            <input class="search-form-control"
                   :placeholder="$t('search')"
                   v-model="teacher_search"/>
          </div>
          <LittleUserTeaser @click.native="teacherSelected(t)"
                            class="mb-2"
                            :class="{ active: tid == t.id }"
                            v-for="t in teachers"
                            :user="t"
                            :key="'teacher-'+t.id"/>
        </div>
      </b-col>
      <b-col md>
        <div class="week-calendar-wrapper" v-if="![4,5].includes($store.state.user.primary_role_id) || tid">
          <full-calendar id="week-calendar"
                         ref="wcalendar"
                         :events="events"
                         :config="weekconfig">
          </full-calendar>
        </div>
        <div class="gray-select-please" v-if="[4,5].includes($store.state.user.primary_role_id) && !tid">{{ $t('choose_a_teacher') }}</div>
      </b-col>
    </b-row>
    <div v-if="students_count == 0 && loaded && !events.length" class="spacecard text-center">
      <StudentSvg class="mt-5"/>
      <div class="text-muted mt-4">{{ $t('first_you_need_to_add_students') }}</div>
      <div class="mt-4 mb-5">
        <router-link class="btn-themed" :to="roleLinkAppendix()+'/students'">{{ $t('students') }}</router-link>
      </div>
    </div>
    <AddClassModal @refresh="refresh"
                   v-if="addclassmodal"
                   :teacher="tid"
                   :giventime="addingnew ? null : giventime"
                   :event="addingnew ? null : selectedEvent"/>
    <ScheduleSettingsModal @refresh="reloadCalendars"/>
  </section>
</template>

<script>
  import moment from 'moment-timezone'
  import { FullCalendar } from 'vue-full-calendar'
  // import 'fullcalendar/dist/fullcalendar.css'
  import '@/assets/sass/fullcalendar.scss'
  import AddClassModal from '@/views/pages/instructor/schedule/AddClassModal'
  import ScheduleSettingsModal from '@/views/pages/instructor/schedule/ScheduleSettingsModal'
  import StudentSearch from '@/views/parts/class/StudentSearch'
  import { LessonsService, TeachersService } from '@/services/api.service'
  import LangHelper from "@/services/lang.helper"
  import StudentSvg from "@/assets/svg/student-g.svg"
  import SearchSvg from "@/assets/svg/search.svg"
  import PlusSvg from "@/assets/svg/plus.svg"
  import LittleUserTeaser from "@/views/teasers/LittleUserTeaser"

  export default {
    components: {
      LittleUserTeaser,
      FullCalendar,
      AddClassModal,
      PlusSvg,
      SearchSvg,
      StudentSearch,
      ScheduleSettingsModal,
      StudentSvg
    },
    data() {
      return {
        room: "",
        giventime: null,
        selectedEvent: null,
        minDuration: 30,
        standardDuration: 60,
        maxDuration: 240,
        students: this.$route.query.students ? (Array.isArray(this.$route.query.students) ? this.$route.query.students : [this.$route.query.students]) : [],
        groups: this.$route.query.groups ? (Array.isArray(this.$route.query.groups) ? this.$route.query.groups : [this.$route.query.groups]) : [],
        tid: this.$route.query.tid ? this.$route.query.tid : null,
        addingnew: false,
        addclassmodal: false,
        students_count: 0,
        loaded: false,
        teacher_search: "",
        allteachers: [],
        teachers: [],
        monthconfig: {
          firstDay: 1,
          defaultView: 'month',
          height: 'auto',
          header: {
            left: 'title',
            center: '',
            right: 'prev,next'
          },
          columnFormat: 'dd',
          locale: LangHelper.getLowcaseLanguage(),
        },
        weekconfig: {
          firstDay: 1,
          headerToolbar: false,
          customButtons: {
            settings: {
              text: ' ',
              click: () => {
                this.$bvModal.show('schedulesettingsmodal')
              }
            },
            next: {
              text: '',
              click: () => {
                this.$refs.wcalendar.fireMethod('next')
                let day = this.$refs.wcalendar.fireMethod('getDate')
                this.imitateDayClickOnMonthCalendar(day)
              }
            },
            prev: {
              text: '',
              click: () => {
                this.$refs.wcalendar.fireMethod('prev')
                let day = this.$refs.wcalendar.fireMethod('getDate')
                this.imitateDayClickOnMonthCalendar(day)
              }
            },
            today: {
              text: this.$t('today'),
              click: () => {
                this.$refs.wcalendar.fireMethod('today')
                let day = this.$refs.wcalendar.fireMethod('getDate')
                this.imitateDayClickOnMonthCalendar(day)
              }
            }
          },
          header: {
            left: 'prev,today,next',
            center: '',
            right: 'settings'
          },
          buttonText: {
            today: this.$t('today'),
          },
          allDaySlot: false,
          columnFormat: 'D dd',
          eventConstraint: {
            // start: moment().format('YYYY-MM-DD'),
            end: moment().add(1,'years').format('YYYY-MM-DD'),
          },
          minTime: this.$store.state.user.schedule_from,
          maxTime: this.$store.state.user.schedule_to,
          timeFormat: 'HH:mm',
          slotLabelFormat: 'HH:mm',
          height: 'auto',
          defaultTimedEventDuration: "01:00",
          slotDuration: "00:15:00",
          slotLabelInterval: "01:00",
          eventOverlap: false,

          // validRange: function() {
          //   return {
          //     start: moment().add(-1, 'days'),
          //   }
          // },

          eventRender: (event, element) => {
            if(event.students) {
              event.students.forEach(student => {
                element.append('<div class="student-name">'+student.name+'</div>')
              })
            }

            // if (event.className.some((val) => ["finished","used"].indexOf(val) !== -1)) {
            //   console.log('finished')
            //   element.draggable = false
            //   element.resizeable = false
            //   event.editable = false
            // }
          },
          select: (start, end) => {
            // if(start.isBefore(moment())) {
            //   this.$refs.wcalendar.fireMethod('unselect')
            //   return
            // }
            let duration = moment.duration(end.diff(start))
            if (duration.asMinutes() < this.minDuration) {
              let newend = moment(start).add(this.standardDuration, 'minutes')
              duration = moment.duration(newend.diff(start))
              this.$refs.wcalendar.fireMethod('renderEvent', {
                start: start,
                end: newend
              },true)
              this.$refs.wcalendar.fireMethod('unselect')
            }
            if(duration.asMinutes() > this.maxDuration) {
              this.$refs.wcalendar.fireMethod('renderEvent', {
                start: start,
                end: moment(start).add(this.maxDuration, 'minutes')
              },true)
              this.$refs.wcalendar.fireMethod('unselect')
            }
            this.giventime = {
              day: moment(start).format('YYYY-MM-DD'),
              time: moment(start).format('HH:mm'),
              duration: Math.max(duration.asMinutes(), this.minDuration),
            }
            this.selectedEvent = null
            this.addclassmodal = true
            this.$nextTick(() => this.$bvModal.show('addclassmodal'))
          },
          selectAllow: (selectInfo) => {
            const duration = moment.duration(selectInfo.end.diff(selectInfo.start))
            return duration.asMinutes() <= this.maxDuration
          },
          eventDrop: async (e, delta, old) => {
            let res = this.apiResponse(await this.saveLessonChange(e))
            if(!res.success) {
              old()
            }
          },
          eventResize: async (e, delta, old) => {
            let res = this.apiResponse(await this.saveLessonChange(e))
            if(!res.success) {
              old()
            }
          },
          eventClick: (e) => {
            this.selectedEvent = e
            this.giventime = null
            this.addingnew = false
            this.addclassmodal = true
            this.$nextTick(() => this.$bvModal.show('addclassmodal'))
          },
          locale: LangHelper.getLowcaseLanguage(),
        },
        events: []
      }
    },
    mounted() {
      this.refresh()
      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        if (modalId === "addclassmodal") {
          this.refresh()
        }
      })
      if([4,5].includes(this.$store.state.user.primary_role_id)) {
        this.loadTeachers()
      }
      setTimeout(() => {
        if(this.$refs.mcalendar) {
          this.$refs.mcalendar.fireMethod('select', moment())
        }
      },400)

    },
    methods: {
      dayClick(e) {
        console.log(e)
        this.$refs.wcalendar.fireMethod('gotoDate', e)
      },
      imitateDayClickOnMonthCalendar(day) {
        this.$refs.mcalendar.fireMethod('gotoDate', day)
        this.$refs.mcalendar.fireMethod('select', day)
      },
      refresh() {
        LessonsService.get(this.students, this.groups, this.tid).then(res => {
          this.events = res.data.data.lessons
          this.students_count = res.data.data.students
          this.loaded = true
        })
      },
      reloadCalendars() {
        this.$refs.wcalendar.fireMethod('option','minTime',this.$store.state.user.schedule_from)
        this.$refs.wcalendar.fireMethod('option','maxTime',this.$store.state.user.schedule_to)
      },
      async saveLessonChange(data) {
        const start = data.start
        const duration = moment.duration(data.end.diff(start))
        let params = {
          duration: duration.asMinutes(),
          day: moment(start).format('YYYY-MM-DD'),
          time: moment(start).format('HH:mm')
        }
        return await LessonsService.update(data.id, params)
        // LessonsService.update(data.id, params).then(res => {
        //   console.log(res)
        // })
      },
      studentsSelected(data, type = "students") {
        if(type === 'students') {
          this.students = data.map(a => a.id)
          this.$router.push({ path: 'schedule', query: { students: this.students } })
        }
        if(type === 'groups') {
          this.groups = data.map(a => a.id)
          this.$router.push({ path: 'schedule', query: { groups: this.groups } })
        }
      },
      addClass() {
        this.addingnew = true
        this.addclassmodal = true
        this.$nextTick(() => this.$bvModal.show('addclassmodal'))
      },
      loadTeachers() {
        TeachersService.get({ for_select: true }).then(res => {
          this.allteachers = res.data.data
          this.teachers = res.data.data
        })
      },
      teacherSelected(teacher) {
        this.$router.push({ path: '/school/schedule', query: { tid: teacher.id } })
      }
    },
    watch: {
      $route() {
        this.students = this.$route.query.students ? (Array.isArray(this.$route.query.students) ? this.$route.query.students : [this.$route.query.students]) : []
        this.groups = this.$route.query.groups ? (Array.isArray(this.$route.query.groups) ? this.$route.query.groups : [this.$route.query.groups]) : []
        this.tid = this.$route.query.tid ? this.$route.query.tid : null
        this.refresh()
      },
      teacher_search(val) {
        if(!val) {
          this.teachers = this.allteachers
          return
        }
        this.teachers = []
        for (var i = 0; i < this.allteachers.length; i++) {
          let ob = this.allteachers[i]
          if(ob.name.toLowerCase().includes(val.toLowerCase())) {
            this.teachers.push(ob)
          }
        }
      }
    },
    metaInfo() {
      return {
        title: this.$t('schedule'),
      }
    },
  }
</script>

<style scoped lang="scss">

  .btn-themed {
    height: 46px;
  }
  .search-wrap {
    margin-top: 40px;
  }
  .mb-24 {
    margin-bottom: 24px;
  }
</style>

